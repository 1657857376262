import React from "react";
import { ListGroup, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Importing avatar1 if you need it, you can uncomment this if required.
// import avatar1 from "../../../../assets/images/user/avatar-1.jpg";

const NavRight = () => {
  const navigate = useNavigate();

  const Logout = async () => {
    // Remove items from localStorage
    localStorage.removeItem("CustomerId");
    localStorage.removeItem("SupportId");
    localStorage.removeItem("InHouseId");
    localStorage.removeItem("OwnerID");

    // Navigate to the home page
    navigate("/");

    // Reload the page after navigation
    window.location.reload();
  };

  return (
    <React.Fragment>
      <ListGroup
        as="ul"
        bsPrefix=" "
        className="navbar-nav ml-auto"
        id="navbar-right"
      >
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown align="start" className="drp-user">
            <Dropdown.Toggle
              as={Link}
              variant="link"
              to="#"
              id="dropdown-basic"
            >
              <i className="icon feather icon-settings" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="profile-notification">
              <div className="pro-head">
                {/* Uncomment if you want to show the avatar */}
                {/* <img src={avatar1} className="img-radius" alt="User Profile" /> */}
                <span>Log Out</span>
                <Link onClick={Logout} className="dud-logout" title="Logout">
                  <i className="feather icon-log-out" />
                </Link>
              </div>
              <ListGroup
                as="ul"
                bsPrefix=" "
                variant="flush"
                className="pro-body"
              >
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

export default NavRight;
