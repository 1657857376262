import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Login from "../src/views/auth/signin/SignIn1"
import routes, { renderRoutes } from './routes';

const App = () => {
  // Define a state variable for data
  const [data, setData] = useState(false)

  useEffect(() => {
    const login = localStorage.getItem('CustomerId');
    const House = localStorage.getItem('InHouseId');
    const OwnerID = localStorage.getItem('OwnerID');


    if (login || House || OwnerID ) {
      setData(true);
    }
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
        {data ? (
          renderRoutes(routes) 
        ) : (
          <Login/>
        )}
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
