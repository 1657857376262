import React, { Suspense, Fragment, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import { BASE_URL } from './config/constant';

const House = localStorage.getItem('InHouseId');
const CustomerId = localStorage.getItem('CustomerId');
const OwnerID = localStorage.getItem('OwnerID');


export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Element />}</Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: 'true',
    path: '/login',
    element: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: 'true',
    path: '/auth/signin-1',
    element: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: 'true',
    path: '/auth/signup-1',
    element: lazy(() => import('./views/auth/signup/SignUp1'))
  },
  {
    exact: 'true',
    path: '/auth/reset-password-1',
    element: lazy(() => import('./views/auth/reset-password/ResetPassword1'))
  }
];




if (House) {
  routes.push(
    {
      path: '*',
      layout: AdminLayout,
      routes: [
        {
          exact: 'true',
          path: '*',
          element: lazy(() => import('./views/InHouse/ComplainAdd'))
        },
        // {
        //   exact: 'true',
        //   path: '/ComplainDetails/:id',
        //   element: lazy(() => import('./views/InHouse/ComplainDetails'))
        // },
        {
          exact: 'true',
          path: '/',
          element: lazy(() => import('./views/InHouse/ComplainAdd'))
        },
        // {
        //   path: '*',
        //   exact: 'true',
        //   element: () => <Navigate to={BASE_URL} />
        // }
      ]
    }
  );
}


if (CustomerId) {
  routes.push(
    {
      path: '*',
      layout: AdminLayout,
      routes: [
        {
          exact: 'true',
          path: '*',
          element: lazy(() => import('./views/Client/dashboard'))
        },
        {
          exact: 'true',
          path: '/',
          element: lazy(() => import('./views/Client/dashboard'))
        },
        {
          exact: 'true',
          path: '/complaint',
          element: lazy(() => import('./views/Client/ComplainAdd'))
        },
        {
          exact: 'true',
          path: '/ComplainDetails/:id',
          element: lazy(() => import('./views/Client/ComplainDetails'))
        },
        {
          path: '*',
          exact: 'true',
          element: () => <Navigate to={BASE_URL} />
        }
      ]
    }
  );
}


if (OwnerID) {
  routes.push(
    {
      path: '*',
      layout: AdminLayout,
      routes: [
        {
          exact: 'true',
          path: '*',
          element: lazy(() => import('./views/InHouse/dashboard'))
        },
        {
          exact: 'true',
          path: '/',
          element: lazy(() => import('./views/InHouse/dashboard'))
        },
        {
          exact: 'true',
          path: '/ComplainDetails/:id',
          element: lazy(() => import('./views/InHouse/ComplainDetails'))
        },
        {
          exact: 'true',
          path: '/Raise',
          element: lazy(() => import('./views/InHouse/ComplainAdd'))
        },
        {
          path: '*',
          exact: 'true',
          element: () => <Navigate to={BASE_URL} />
        }
      ]
    }
    // {
    //   path: '*',
    //   layout: AdminLayout,
    //   routes: [
    //     {
    //       exact: 'true',
    //       path: '/',
    //       element: lazy(() => import('./views/Owner/dashboard'))
    //     },
    //     {
    //       exact: 'true',
    //       path: '/ComplainDetails/:id',
    //       element: lazy(() => import('./views/Owner/ComplainDetails'))
    //     },
    //     {
    //       exact: 'true',
    //       path: '/AddCustomer',
    //       element: lazy(() => import('./views/Owner/AddCostomer'))
    //     },
    //     {
    //       exact: 'true',
    //       path: '/AddInhouse',
    //       element: lazy(() => import('./views/Owner/AddInhouse'))
    //     },
    //     {
    //       exact: 'true',
    //       path: '/all',
    //       element: lazy(() => import('./views/Owner/Alldetails/All'))
    //     },
    //     {
    //       exact: 'true',
    //       path: '/comnopy/:id',
    //       element: lazy(() => import('./views/Owner/Alldetails/Conpony'))
    //     },
    //     {
    //       exact: 'true',
    //       path: '/inhouse/:id',
    //       element: lazy(() => import('./views/Owner/Alldetails/Inhouse'))
    //     },
    //     {
    //       path: '*',
    //       exact: 'true',
    //       element: () => <Navigate to={BASE_URL} />
    //     }
    //   ]
    // }
  );
}

export default routes;
