const CustomerId = localStorage.getItem('CustomerId');
const InHouse = localStorage.getItem('InHouseId');
const OwnerID = localStorage.getItem('OwnerID');


const menuItems = {
  items: [
    {
      id: 'member-data',
      type: 'group',
      children: []
    }
  ]
};

if (CustomerId) {
  menuItems.items[0].children.push(
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      icon: 'feather icon-home',
      url: '/'
    },
    {
      id: ' Raisecomplaint',
      title: 'Request Service',
      type: 'item',
      icon: 'fa fa-address-card',
      url: '/complaint'
    }
  );
} else if (InHouse) {
  menuItems.items[0].children.push(
    // {
    //   id: 'dashboard',
    //   title: 'Dashboard',
    //   type: 'item',
    //   icon: 'feather icon-home',
    //   url: '/'
    // },
    {
      id: 'RequestService',
      title: 'Request Service',
      type: 'item',
      icon: 'feather icon-home',
      url: '/'
    }
  );
} else if (OwnerID) {
  menuItems.items[0].children.push(

       {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      icon: 'feather icon-home',
      url: '/'
    },
    {
      id: 'RequestService',
      title: 'Request Service',
      type: 'item',
      icon: 'feather icon-home',
      url: '/Raise'
    }
    // {
    //   id: 'dashboard',
    //   title: 'Dashboard',
    //   type: 'item',
    //   icon: 'feather icon-home',
    //   url: '/'
    // },
    // {
    //   id: ' AddCustomer',
    //   title: 'Add Customer',
    //   type: 'item',
    //   icon: 'fa fa-user',
    //   url: '/AddCustomer'
    // },
    // {
    //   id: ' AddInhouse',
    //   title: 'Add Inhouse',
    //   type: 'item',
    //   icon: 'fa fa-user',
    //   url: '/AddInhouse'
    // },
    // {
    //   id: ' AddInhouse',
    //   title: 'Details',
    //   type: 'item',
    //   icon: 'fa fa-user',
    //   url: '/all'
    // }
  );
}

export default menuItems;
