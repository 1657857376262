import PropTypes from "prop-types";
import React from "react";
import { Row, Col, Button, Alert } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";

const FirebaseLogin = ({ className, ...rest }) => {

  const loginApiCall = async (values, actions) => {
    axios
      .post("https://customerandtask-app.onrender.com/CustomerSupport/sdfghjklgfdsartfyuiopytr", {
        Email: values.email,
        Password: values.password
      })
      .then((response) => {
        // console.log(response.data.status);
        if (response.data.status === "Login as Customer") {
          localStorage.setItem("CustomerId", response.data.data._id);
          window.location.reload();
        }
        if (response.data.status === "Login as InHouse") {  
          localStorage.setItem("InHouseId", response.data.data._id);
          window.location.reload();
        }
        if (response.data.status === "customer support") {
          localStorage.setItem("OwnerID", response.data.data._id);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    // console.log(values);

  };

  return (
    <React.Fragment>
      <div id="login" style={{ display: "block" }}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
          })}
          onSubmit={(values, actions) => loginApiCall(values, actions)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              className={className}
              {...rest}
            >
              <div className="form-group mb-3">
                <input
                  className="form-control"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  placeholder="Enter email"
                />
                {touched.email && errors.email && (
                  <small className="text-danger form-text">
                    {errors.email}
                  </small>
                )}
              </div>
              <div className="form-group mb-4">
                <input
                  className="form-control"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  placeholder="Enter password"
                />
                {touched.password && errors.password && (
                  <small className="text-danger form-text">
                    {errors.password}
                  </small>
                )}
              </div>
              {errors.submit && (
                <Col sm={12}>
                  <Alert variant="danger">{errors.submit}</Alert>
                </Col>
              )}
              {/* <div className="custom-control custom-checkbox text-end mb-4 mt-2">
                <p
                  className="mb-2 text-muted"
                  style={{ cursor: "pointer" }}
                >
                  Forgot password?
                </p>
              </div> */}
              <Row>
                <Col mt={2}>
                  <Button
                    className="btn-block"
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="primary"
                  >
                    Signin
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

FirebaseLogin.propTypes = {
  className: PropTypes.string,
};

export default FirebaseLogin;
