import axios from 'axios';
import React, { createContext, useState } from 'react';

const ApiContext = createContext();

const ApiContextProvider = ({ children }) => {

    // const supportId = localStorage.getItem("SupportId");

    const [CSussece, setCSussece] = useState("")

    const loginApiCall = async (values) => {
        axios
            .post("https://customerandtask-app.onrender.com/ConplainController/dfshfgjhfgjfghjftghjfghjgh", {
                PresentStatus: values.PresentStatus,
                Requirement: values.Requirement,
                Solution: values.Solution,
                Benefit: values.Benefit,
                Project: values.Project,
                Module: values.Module,
                Photos: values.photos,
                ContactPerName: values.ContactPerName,
                ContactPer: values.ContactPer,
                CompanyName: values.CompanyName,
                // SuportTeamId: supportId,
                Type: values.Type,
                Requirement_Type: values.Requirement_Type,
                Partition: "Customer Side"
            })
            .then((response) => {
                if (response.data.status === "Conplain add") {
                    setCSussece(response.data.status)
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [companydata, setcompanydata] = useState()

    const showCompany = async () => {
        axios
            .get(`https://customerandtask-app.onrender.com/CustomerSupport/wsetwertgvsdxsadf4556666`)
            .then((response) => {
                setcompanydata(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const AddComplainbyCom = async (values) => {
        axios
            .post("https://customerandtask-app.onrender.com/ConplainController/dfshfgjhfgjfghjftghjfghjgh", {
                PresentStatus: values.PresentStatus,
                Requirement: values.Requirement,
                Solution: values.Solution,
                Benefit: values.Benefit,
                Project: values.Project,
                Module: values.Module,
                Photos: values.photos,
                ContactPerName: values.ContactPerName,
                ContactPer: values.ContactPer,
                CompanyName: values.CompanyName,
                // SuportTeamId: supportId,
                Type: values.Type,
                Requirement_Type: values.Requirement_Type,
                Partition: values.Partition,
            })
            .then((response) => {
                if (response.data.status === "Conplain add") {
                    setCSussece(response.data.status)
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [ComplainData, setComplainData] = useState()

    const CustomerDashBordfun = async (id) => {
        axios
            .get(`https://customerandtask-app.onrender.com/ConplainController/dfshfgjhfgjfghjsdfghjgh/${id}`)
            .then((response) => {
                setComplainData(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [ComplainfData, setComplainfData] = useState()

    const Complainfind = async (id) => {
        axios
            .get(`https://customerandtask-app.onrender.com/ConplainController/dfshfsdfgjftghjfghjgh/${id}`)
            .then((response) => {
                setComplainfData(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [InhousedataData, setInhousedataData] = useState()

    const InHouseDashBordfun = async () => {
        axios
            .get(`https://customerandtask-app.onrender.com/ConplainController/dfsfdgjhfg3454575hjfghjgh`)
            .then((response) => {
                setInhousedataData(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [accept, setaccept] = useState("")
    const HouseID = localStorage.getItem('InHouseId');

    const Statuse_accept = async (id) => {
        axios
            .post(`https://customerandtask-app.onrender.com/ConplainController/fteryhdfhb8dgh46df4g6e7rtgdfg/${id}`, {
                AcceptID: HouseID
            })
            .then((response) => {
                setaccept(response.data.status)
                setInhousedataData(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const Conplain_Complate = async (id) => {
        axios
            .post(`https://customerandtask-app.onrender.com/ConplainController/fteryhdfhb8dgh46df4g6e7rtgdrs/${id}`, {
                AcceptID: HouseID
            })
            .then((response) => {
                setaccept(response.data.status)
                setInhousedataData(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };
    const [developer, setdeveloper] = useState()
    const find_dev = async () => {
        axios
            .get(`https://customerandtask-app.onrender.com/ConplainController/fteryhdfhb8dgh4sde7rtgdrs`)
            .then((response) => {
                setdeveloper(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [suport, setsuport] = useState()
    const find_suport = async () => {
        axios
            .get(`https://customerandtask-app.onrender.com/ConplainController/fteryhdfwe46d345e7rtgdrs`)
            .then((response) => {
                setsuport(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const Conplain_passdev = async (id, selectedDev, description) => {
        axios
            .post(`https://customerandtask-app.onrender.com/ConplainController/fteryhdfhb8dgh46d345e7rtgdrs/${id}`, {
                SuportTeamId: selectedDev,
                DescripTione: description
            })
            .then((response) => {
                setaccept(response.data.status)
                setInhousedataData(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const Conplain_passsuport = async (id, SuportTeamId, description) => {
        axios
            .post(`https://customerandtask-app.onrender.com/ConplainController/fteryhdfhb8d23456345e7rtgdrs/${id}`, {
                SuportTeamId: SuportTeamId,
                DescripTione: description
            })
            .then((response) => {
                setaccept(response.data.status)
                setInhousedataData(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };
    const [find, setfind] = useState()
    const findall = async () => {
        axios
            .get(`https://customerandtask-app.onrender.com/ConplainController/asrfeer346456456dfgdfgdfg`)
            .then((response) => {
                setfind(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [findc, setfindc] = useState()
    const findallc = async () => {
        axios
            .get(`https://customerandtask-app.onrender.com/AddCustomer/wsetwertgvsdxsadf4556666`)
            .then((response) => {
                setfindc(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };
    const [addclientdata, setaddclientdata] = useState()
    const addclient = async (el) => {
        axios
            .post(`https://customerandtask-app.onrender.com/AddCustomer/dfghjhgfdsdfgmgvfcdxcv535`, {
                CompanyName: el.companyname,
                Email: el.email,
                Password: el.password,
                ContactNo: el.contactnum,
                SuportTeamId: el.supportingTeam
            })
            .then((response) => {
                setaddclientdata(response.data.status)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [addinhousedata, setaddinhousedata] = useState()
    const addInhouse = async (el) => {
        axios
            .post(`https://customerandtask-app.onrender.com/DynamicInHouseAccounts/iuyusadjhvfbcsamcbhjhdfh2345`, {
                Name: el.name,
                Email: el.email,
                Role: el.role,
                MobileNumber: el.mobilenumber,
                DynamicCustomerID: el.dynamiccustomerid,
                Password: el.password
            })
            .then((response) => {
                setaddinhousedata(response.data.status)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [companyname, setcompanyname] = useState()
    const companynamefun = async (id) => {
        axios
            .get(`https://customerandtask-app.onrender.com/AddCustomer/sdfwerer456456dfgdfg/${id}`)
            .then((response) => {
                setcompanyname(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };


    const [cusallcomplain, setcusallcomplain] = useState()
    const cusallcomplainfun = async (id) => {
        axios
            .get(`https://customerandtask-app.onrender.com/ConplainController/asrfeer34645fgdfgdfgr564645/${id}`)
            .then((response) => {
                setcusallcomplain(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [inhousepersonel, setinhousepersonel] = useState()
    const inhousepersonelfun = async (id) => {
        axios
            .get(`https://customerandtask-app.onrender.com/DynamicInHouseAccounts/gdfgrrtertevsdrwesdfsdfds/${id}`)
            .then((response) => {
                setinhousepersonel(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const unpdatecustomer = async (id, selectedPerson) => {
        axios
            .post(`https://customerandtask-app.onrender.com/AddCustomer/safsddfgryrty45645654dfgdfg/${id}`, {
                SuportTeamId: selectedPerson
            })
            .then((response) => {
                setcompanyname(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const unpdateinhouse = async (id, selectedPerson) => {
        axios
            .post(`https://customerandtask-app.onrender.com/DynamicInHouseAccounts/sdgdfgfd54575674sddfsd/${id}`, {
                DynamicCustomerID: selectedPerson
            })
            .then((response) => {
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const complainecomplate = async (id) => {
        axios
            .get(`https://customerandtask-app.onrender.com/ConplainController/dfsert45457asdsdffh/${id}`)
            .then((response) => {
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const complainereject = async (rejectReason, id) => {
        axios
            .post(`https://customerandtask-app.onrender.com/ConplainController/dfsert45457asdsdffh/${id}`, {
                DescripTione: rejectReason,
                SuportTeamId: HouseID
            })
            .then((response) => {
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [Allmodule, setAllmodule] = useState()
    const Allmodulefuntion = async (id) => {
        axios
            .get(`https://customerandtask-app.onrender.com/TablemangeController/show_module/${id}`)
            .then((response) => {
                setAllmodule(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [AllProject, setAllProject] = useState()
    const AllProjectfuntion = async () => {
        axios
            .get(`https://customerandtask-app.onrender.com/TablemangeController/show_project`)
            .then((response) => {
                setAllProject(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const [AllType, setAllType] = useState()

    
    const Alltypefuntion = async (id) => {
        axios
            .get(`https://customerandtask-app.onrender.com/TablemangeController/show_Type/${id}`)
            .then((response) => {
                setAllType(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    const AddCom = async (data) => {
        console.log(data);
        axios
            .post(`https://customerandtask-app.onrender.com/CustomerSupport/DynamicCustomer_Comn/${data.Comn}`, {
                Name: data.Name,
                ContactNo: data.Number
            })
            .then((response) => {
                setcompanydata(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };
    return (
        <ApiContext.Provider value={{
            loginApiCall,
            CSussece,
            setCSussece,
            ComplainData,
            CustomerDashBordfun,
            ComplainfData,
            Complainfind,
            InHouseDashBordfun,
            InhousedataData,
            Statuse_accept,
            accept,
            setaccept,
            Conplain_Complate,
            find_dev, developer,
            find_suport, suport,
            Conplain_passdev,
            Conplain_passsuport,
            findall, find,
            findallc, findc,
            addclientdata, addclient, setaddclientdata,
            setaddinhousedata, addinhousedata, addInhouse,
            companynamefun, companyname,
            cusallcomplain, cusallcomplainfun,
            inhousepersonelfun, inhousepersonel,
            unpdatecustomer,
            unpdateinhouse,
            complainecomplate,
            complainereject,
            Allmodulefuntion, Allmodule,
            AllProjectfuntion, AllProject,
            Alltypefuntion, AllType, AddComplainbyCom,showCompany,companydata,AddCom
        }}>
            {children}
        </ApiContext.Provider>
    );
};

export { ApiContext, ApiContextProvider };
