import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import { ConfigProvider } from "./contexts/ConfigContext";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApiContextProvider } from "./contexts/ApiContext";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ConfigProvider>
    <ApiContextProvider>
      <App />
    </ApiContextProvider>
  </ConfigProvider>
);

reportWebVitals();
